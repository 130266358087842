'use client';

import { createContext, useContext } from "react";

const EnvContext = createContext<{ envValue: Record<string, string> }>({ envValue: {} });

export function EnvProvider({ envValue, children }: { envValue: Record<string, string>; children: React.ReactNode }) {
  return <EnvContext.Provider value={{ envValue }}>{children}</EnvContext.Provider>;
}

export function useEnv() {
  return useContext(EnvContext);
};