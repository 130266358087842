'use client';

import Loader from '@/components/Loader';
import styles from './layout.module.scss';
import useApi from '@/hooks/useApi';
import {
  setChatData,
  updateChatCarouselData,
  updateChatCarouselDataatStart,
} from '@/lib/features/chatSlice';
import { setError, setErrorToast } from '@/lib/features/deviceSlice';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { fetchChatdata, reportEvent } from '@/utils/api';
import {
  permanentRedirect,
  useParams,
  useRouter,
  useSearchParams,
} from 'next/navigation';
import { useEffect, useState } from 'react';

type ICharacterDetailsData = {
  status: string;
  body: Record<string, string> | null;
};

export default function Page() {
  const { charId, feedId } = useParams();
  const searchParams = useSearchParams();
  const chatId = searchParams.get('chatId');
  const characterId = searchParams.get('charId');
  const [enableTelegramContest, setEnableTelegramContest] = useState(searchParams.get('telegramContest') === 'true');
  const [loading, showLoading] = useState(false);
  const { chatCarouselData } = useAppSelector((state: RootState) => state.chat);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    id: userDataId,
    age,
    gender,
  } = useAppSelector((state: RootState) => state.user.userData);
  const { responseData, fetchData, error } = useApi<ICharacterDetailsData>(
    'GET',
    `/ai/api/char/v1/contestCharacter`
  );
  useEffect(() => {
    if (enableTelegramContest) {
    } else {
      if (chatId && characterId) {
        permanentRedirect(`/dashboard/chats${window.location.search}`);
      } else {
        if (!feedId && !charId) {
          const params = window.location.search;
          if (params.length) {
            permanentRedirect(
              `/dashboard/characters${params}&category=recommended`
            );
          } else {
            permanentRedirect('/dashboard/characters?category=recommended');
          }
        }
      }
    }
  }, [enableTelegramContest]);
  useEffect(() => {
    if (userDataId && enableTelegramContest) {
      router.replace(`/dashboard`);
      showLoading(true);
      fetchData();
    }
  }, [userDataId]);
  useEffect(() => {
    if (responseData && responseData.status === 'Success') {
      const { characterId } = responseData.body as Record<string, string>;
      fetchChatdata(userDataId as string, characterId).then((chatData) => {
        if (!chatData) {
          dispatch(setErrorToast({ showErrorToast: true }));
          return;
        }
        const { status } = chatData;
        if (status === 'Success') {
          const {
            body: {
              chatHistoryList,
              chatSessionId,
              characterId,
              characterImageUrl,
              characterName,
              description,
              voiceId,
              skillSet,
              tip,
              createdBy,
              disabledBy,
              createdByUserId
            },
          } = chatData;
          dispatch(
            setChatData({
              chatSessionId,
              chatHistoryList,
              characterId,
              characterImageUrl,
              characterName,
              description,
              voiceId,
              skillSet,
              tip,
              createdBy,
              disabledBy,
              createdByUserId
            })
          );
          if (chatCarouselData.length) {
            const isChatPresent = chatCarouselData.filter(
              (chat) => chat.characterId === characterId
            ).length;
            if (!isChatPresent) {
              dispatch(
                updateChatCarouselDataatStart({
                  chatSessionId,
                  characterId,
                  user_id: userDataId as string,
                  characterName,
                  characterImageUrl,
                })
              );
            }
          } else {
            dispatch(
              updateChatCarouselData({
                chatSessionId,
                characterId,
                user_id: userDataId as string,
                characterName,
                characterImageUrl,
              })
            );
          }
          router.push(
            `/dashboard/chats?chatId=${chatSessionId}&charId=${characterId}`
          );
          showLoading(false);
        } else {
          reportEvent({
            eventName: 'feErrorScreen',
            userId: userDataId as string,
            age: age,
            gender: gender,
            extraData: { error: `Session API Fail` },
          });
          dispatch(setError({ showErrorScreen: true }));
        }
        showLoading(false);
      });
    } else if (error || (responseData && responseData?.status !== 'Success')) {
      setEnableTelegramContest(false);
      showLoading(false);
    }
  }, [responseData, error]);

  return loading ? (
    <div className={styles.loadingContainer}>
      <Loader imageSpec={48} />
    </div>
  ) : null;
}
