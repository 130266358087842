import {
  setErrorToast,
  setErrorToastMessage,
} from '@/lib/features/deviceSlice';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { RootState } from '@/lib/store';
import { getUtmParams } from '@/utils';
import { getFeDeviceDetails } from '@/utils/device';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import { getAuth, signOut } from 'firebase/auth';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useEnv } from "@/app/context/EnvContext";

type IError = {
  body: {
    message: string;
    authUserId?: string;
    remainingMinutes?: number;
    remainingCount?: number;
  };
  status: string;
};

function useApi<T>(
  method: string,
  url: string,
  data?:
    | Record<string, string | null | boolean>
    | Record<string, Array<string>>
    | { likesMeta: Record<string, string[]> }
    | Record<string, string | boolean>[]
) {
  const [responseData, setData] = useState<T | null>(null);
  const { envValue } = useEnv();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const { id } = useAppSelector((state: RootState) => state.user.userData);
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const handleSignOut = () => {
    const auth = getAuth();
    dispatch(setErrorToastMessage(t('pleaseLoginAgain')));
    setTimeout(() => {
      dispatch(setErrorToast({ showErrorToast: true }));
    }, 100);
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        dispatch(setErrorToast({ showErrorToast: true }));
      });
  };
  const fetchData = async (updatedUrl?: string) => {
    setIsLoading(true);
    setData(null);
    setError(null);
    const auth = getAuth();
    const { currentUser } = auth;
    if (!currentUser) {
      handleSignOut();
      return;
    }
    let accessToken: string | undefined = undefined;
    try {
      accessToken = await currentUser?.getIdToken();
    } catch (e) {
      Bugsnag.notify(
        new Error(`Token Generation Failed: ${e}`) as NotifiableError,
        (event) => {
          event.addMetadata('userData', {
            uaSessionId: window.sessionStorage?.getItem('fe_uaSessionId'),
            uaId: window.localStorage?.getItem('fe_uaId'),
            userId: id,
          });
          if (typeof window !== 'undefined') {
            event.addMetadata('clientInfo', {
              userAgent: navigator?.userAgent,
              visibilityState: document?.visibilityState,
              onLine: navigator?.onLine,
              utm: getUtmParams(),
              url: window?.location?.href,
              os: getFeDeviceDetails().feOsName,
              device: getFeDeviceDetails().feDeviceType,
            });
          }
        }
      );
      if ((e as any).code === 'auth/user-token-expired') {
        handleSignOut();
        return;
      } else {
        accessToken = await currentUser?.getIdToken();
      }
    }
    const options: {
      method: string;
      body?: string;
      headers: Record<string, string>;
    } = {
      method,
      headers: {
        client: 'nowgg',
        Authorization: `WsupV1 ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
    if (data) {
      const uaId = window.localStorage?.getItem('fe_uaId');
      const uaSessionId = window.sessionStorage?.getItem('fe_uaSessionId');
      const wsupUaId = window.localStorage?.getItem('wsup_uaId');
      const wsupUaSessionId =
        window.sessionStorage?.getItem('wsup_uaSessionId');
      const feOsName = getFeDeviceDetails().feOsName;
      const feDeviceType = getFeDeviceDetails().feDeviceType;
      const utmCampaign = sessionStorage?.getItem('utmCampaign');
      const utmSource = sessionStorage?.getItem('utmSource');
      const utmMedium = sessionStorage?.getItem('utmMedium');
      const pageReferrer =
        sessionStorage?.getItem('pageReferrer') || document.referrer;
      if (Array.isArray(data)) {
        data[0].utmCampaign = utmCampaign as string;
        data[0].utmSource = utmSource as string;
        data[0].utmMedium = utmMedium as string;
        data[0].uaId = uaId as string;
        data[0].uaSessionId = uaSessionId as string;
        data[0].feOsName = feOsName;
        data[0].feDeviceType = feDeviceType;
        data[0].wsupUaId = wsupUaId as string;
        data[0].wsupUaSessionId = wsupUaSessionId as string;
        data[0].pageReferrer = pageReferrer as string;
        options.body = JSON.stringify([...data]);
      } else {
        options.body = JSON.stringify({
          ...data,
          uaId,
          uaSessionId,
          wsupUaId,
          wsupUaSessionId,
          feOsName,
          feDeviceType,
          utmCampaign,
          utmSource,
          utmMedium,
          pageReferrer,
        });
      }
    }
    const callUrl = `${envValue.NEXT_PUBLIC_API_HOST}${updatedUrl ? updatedUrl : url}`;
    try {
      const res = await fetch(callUrl, options);
      const response = await res.json();
      if (
        response.status === 'Success' ||
        response.status === 'FailureExplicitContent' ||
        response.status === 'FailureImageTypeNotSupported'
      ) {
        setError(null);
        setTimeout(() => {
          setData(response);
        }, 100);
      } else {
        setData(null);
        setTimeout(() => {
          setError(response);
        }, 100);
      }
    } catch (e) {
      setData(null);
      setTimeout(() => {
        setError(e as IError);
        if ((e as IError).status !== 'FailureUnauthorized') {
          Bugsnag.notify(
            new Error(`API Fail: ${e}`) as NotifiableError,
            (event) => {
              event.addMetadata('userData', {
                uaSessionId: window.sessionStorage?.getItem('fe_uaSessionId'),
                uaId: window.localStorage?.getItem('fe_uaId'),
                userId: id,
                message: (e as IError).body?.message,
              });
              if (typeof window !== 'undefined') {
                event.addMetadata('clientInfo', {
                  userAgent: navigator?.userAgent,
                  visibilityState: document?.visibilityState,
                  onLine: navigator?.onLine,
                  utm: getUtmParams(),
                  url: window?.location?.href,
                  os: getFeDeviceDetails().feOsName,
                  device: getFeDeviceDetails().feDeviceType,
                });
              }
            }
          );
        }
      }, 100);
    }
    setIsLoading(false);
  };
  return { responseData, isLoading, error, fetchData };
}

export default useApi;
